/* Root variables for theming */
:root {
  --background-color: #151413;
  --primary-color: #8d2c1d;
  --secondary-color: #222;
  --text-color: #ffffff;
  --input-background-color: #222222;
  --input-border-color: #8e2d1d;
  --button-hover-color: #b33a2d;
  --button-color: #8d2c1d;
  --shadow-color: rgba(0, 0, 0, 0.5);
}

/* General Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: "Roboto", sans-serif;
  line-height: 1.6;
}

/* Navbar Styling */
.navbar {
  position: sticky;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between; /* Align logo to the left and links to the right */
  align-items: center; /* Vertically center logo and links */
  padding: 15px 30px;
  background-color: var(--primary-color);
  color: var(--text-color);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.navbar-logo h1 {
  margin: 0;
  font-size: 24px;
  font-weight: bold;
}

.navbar-links {
  list-style: none;
  display: flex;
  gap: 20px; /* Add spacing between links */
  align-items: center; /* Ensure links and buttons are vertically centered */
}

.navbar-links li {
  margin: 0;
}

.navbar-links a,
.logout-btn {
  color: var(--text-color);
  text-decoration: none;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: var(--input-background-color);
  transition: all 0.3s ease;
}

.navbar-links a:hover,
.logout-btn:hover {
  background-color: var(--button-hover-color);
}

.logout-btn {
  border: none;
  cursor: pointer;
  padding: 10px 20px;
  background-color: var(--input-background-color);
  color: var(--text-color);
  transition: all 0.3s ease;
}

/* Ensure everything is centered and aligned properly */
.navbar-links,
.logout-btn {
  display: flex;
  align-items: center; /* Vertically center everything */
}

/* Dashboard styling */
.file-list {
  list-style: none;
  padding: 0;
}

.file-item {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: var(--secondary-color);
  margin: 10px 0;
  border-radius: 5px;
}

.file-item button {
  padding: 5px 10px;
  background-color: var(--button-color);
  color: var(--text-color);
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.file-item button:hover {
  background-color: var(--button-hover-color);
}

/* Table styling */
.file-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.file-table th,
.file-table td {
  padding: 12px 15px;
  border-bottom: 1px solid var(--input-border-color);
  text-align: left;
}

.file-table th {
  background-color: var(--secondary-color);
  color: var(--text-color);
  font-weight: bold;
}

.file-table td {
  background-color: var(--background-color);
  color: var(--text-color);
}

.file-table tr:hover td {
  background-color: var(--secondary-color);
}

/* Center the content in the Actions column */
.file-table th:last-child,
.file-table td:last-child {
  text-align: center; /* Centering the Info icon in the last column */
}

/* Info Icon styling */
.info-icon {
  font-size: 20px;
  color: var(--button-color);
  cursor: pointer;
  transition: color 0.3s ease;
}

.info-icon:hover {
  color: var(--button-hover-color);
}

/* Popup styling */
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background-color: var(--secondary-color);
  padding: 30px;
  border-radius: 10px;
  width: 500px; /* Make the popup larger */
  text-align: center;
}

.popup-content h3 {
  margin-bottom: 10px;
}

.popup-content button {
  margin-top: 10px;
  padding: 8px 16px;
}

/* AdminPage popup styling */
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Darken the background */
  backdrop-filter: blur(10px); /* Apply blur to the background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background-color: rgba(
    28,
    28,
    30,
    0.95
  ); /* Semi-transparent background for the content */
  padding: 20px;
  border-radius: 8px;
  max-height: 80vh; /* Limit the height to 80% of the viewport */
  overflow-y: auto; /* Add scrollbar if content overflows */
  width: 80%; /* Set the width to 80% of the screen */
  max-width: 800px; /* Set a maximum width */
}

.file-table th,
.file-table td {
  padding: 12px 15px;
  border-bottom: 1px solid #333;
}

.file-table th {
  background-color: #8d2c1d;
  color: #fff;
}

.file-table td {
  background-color: #1c1c1e;
  color: #fff;
}

button {
  background-color: #8d2c1d;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
}

button:hover {
  background-color: #b33a2d;
}

.download-btn {
  color: #8d2c1d;
  text-decoration: none;
  font-size: 18px;
  padding: 5px;
}

.download-btn:hover {
  color: #b33a2d;
  cursor: pointer;
}

/* Storage and download button styling */
.storage-info {
  margin-bottom: 20px;
}

.progress-bar-container {
  width: 100%;
  height: 20px;
  background-color: #e0e0e0;
  border-radius: 10px;
  overflow: hidden;
  margin: 10px 0;
  position: relative;
}

.progress-bar {
  height: 100%;
  transition: width 2s ease-out; /* Update for smoother animation */
}

.upgrade-container {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.upgrade-container p {
  margin: 0;
  font-size: 14px;
}

.upgrade-container button {
  padding: 8px 12px;
  background-color: var(--button-color);
  color: rgb(255, 255, 255);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: fit-content; /* Adjust button size to fit content */
}

.upgrade-container button:hover {
  background-color: var(--button-hover-color);
}

/* Added smooth transition for animated text ticking up */
.storage-info p {
  font-size: 16px;
  font-weight: bold;
  transition: color 0.5s ease-out;
}

/* Link container styling */
.link-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
  background-color: var(--background-color);
  padding: 5px;
  border-radius: 5px;
  border: 1px solid var(--input-border-color);
}

.download-link {
  flex-grow: 1;
  background: none;
  border: none;
  color: var(--text-color);
  padding: 8px;
  text-align: left;
  height: 40px; /* Set height to match the button */
  line-height: 40px; /* Vertically center text */
  overflow-x: auto;
}

.copy-button {
  background-color: var(--button-color);
  border: none;
  width: 40px; /* Set fixed width for the button */
  height: 40px; /* Same height as the download link input */
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
  border-radius: 3px;
}

.copy-button:hover {
  background-color: var(--button-hover-color);
}

.copy-button svg {
  font-size: 18px; /* Icon size */
  color: white;
}

/* App Container */
.app-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.content {
  margin-top: 80px; /* Offset for the sticky navbar */
}

/* General container styling */
.container {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background-color: var(--secondary-color);
  border-radius: 10px;
  box-shadow: 0px 4px 15px var(--shadow-color);
}

/* Input and button styling */
input[type="email"],
input[type="password"],
input[type="file"],
button {
  width: 100%;
  padding: 12px;
  margin: 10px 0;
  border-radius: 5px;
  border: 2px solid var(--input-border-color);
  background-color: var(--input-background-color);
  color: var(--text-color);
  transition: all 0.3s ease;
}

button {
  background-color: var(--button-color);
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

button:hover {
  background-color: var(--button-hover-color);
}

input:hover,
button:hover {
  box-shadow: 0px 0px 10px var(--button-hover-color);
}

input:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0px 0px 10px var(--primary-color);
}

/* Responsive Navbar for mobile */
@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    padding: 10px;
  }

  .navbar-links {
    flex-direction: column;
    gap: 10px;
  }

  .navbar-logo h1 {
    margin-bottom: 10px;
  }

  .container {
    width: 95%;
  }
}

/* Fade-in effect */
.fade-in {
  opacity: 0;
  animation: fadeIn 1s ease-in forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}
